import axios from "axios";

export const sendInvitaion = async (payload) => {
  return await axios.post(`/workspace/invites`, payload);
};

export const acceptInvitation = async (payload) => {
  try {
    return await axios.post(`/workspace/invites/accept`, payload);
  } catch (error) {
    return error.response;
  }
};

export const declineInvitaion = async (payload) => {
  try {
    return await axios.post(`/workspace/invites/decline`, payload);
  } catch (error) {
    return;
  }
};

export const getInvitationInfo = async (token) => {
  try {
    return await axios.get(`/workspace/invites`, {
      params: { t: token },
    });
  } catch (error) {
    return error.response;
  }
};

export const purchaseOneUser = async () => {
  return await axios.get("/workspace/invites/purchase/one_user");
};

export const updateInviteUser = async (id, payload) => {
  return await axios.patch(`/workspace/member/update/${id}`, payload);
};

export const deleteInviteUser = async (id) => {
  return await axios.delete(`/workspace/member/delete/${id}`);
};
