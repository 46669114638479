<template>
  <div style="background-color: white;min-height: 100vh;">
    <b-row class="d-flex flex-row  align-items-center justify-content-center w-100 " no-gutters>
      <b-col cols="12" md="12" class="mb-4 pt-5">
        <h6 class="text-dark font-primary font-weight-bold mb-xl-4 title  d-xl-inline">
          Choose the right package to meet your budget and business needs
        </h6>
      </b-col>
      <b-col cols="12" md="12">
        <div class="container">
          <div class="top">
            <div class="toggle-btn">
              <label class="switch">
                <input type="checkbox" id="checbox" v-model="isAnnual" />
                <span class="slider round">
                  <button :class="{ 'active': isMonthly }" class="btn" @click="toggleMonthly">Monthly</button>
                  <button :class="{ 'active': !isMonthly }" class="btn" @click="toggleAnnually">Annually</button>
                </span>
              </label>
            </div>
          </div>
        </div>
        <b-row
              class="d-flex flex-row flex-nowrap align-items-center justify-content-center justify-self-center px-2"
              no-gutters
            >
            <span
            class="font-primary font-weight-normal text-main-green text-center"
            >You can also enter your promo code after selecting your package and checking out.</span>
            </b-row>
      </b-col>

      <b-col cols="12" md="10">
        <b-row class="d-flex flex-row flex-wrap align-items-center justify-content-center w-100 px-4" no-gutters>

          <b-col class="d-flex flex-column align-items-center justify-content-center  w-100 " no-gutterss>
            <p v-if="!this.$route.query.lockedAccount"  class="pt-2 ml-3 text-primary font-weight-bold font-size" style="text-align: center">
                           Try Free for {{trialPeriodDays}} Days
            </p>
            <b-card no-body
              class="position-relative overflow-hidden border-1 report-card-row w-100 justify-content: space-between; display: flex; flex-direction: column;"
              style="border-color: rgba(11, 155, 125, 0.1);border-width: 5px">
              <div style="min-height: 150px;max-height: 150px">
                <div class="card-title">
                  <h5 class="text-dark font-primary font-weight-bold">
                    Figa Green
                  </h5>
                </div>
                <p class="sub-title text-muted text-wrap ">
                  Basic
                </p>
                <h4 class="text-dark font-primary font-weight-bold price">
                  {{ this.price_green }}
                </h4>
                <p v-if="this.currencyForCountry == 'NZD' || this.currencyForCountry == 'AUD' " class="sub-title text-muted">+GST/Month</p>
                <p v-else class="sub-title text-muted">+VAT/Month</p>

              </div>
              <b-col class="d-flex flex-column align-items-start justify-content-start px-4 py-2" cols="12">
                <div class="d-flex align-items-center" v-for="(i, index) in FigaGreenfeatures" :key="index">

                  <b-icon-check-circle-fill class="text-success mr-2"></b-icon-check-circle-fill>
                  <span class="text-muted featureText" style="font-size: 15px;">{{ i.feature }}</span>

                </div>
              </b-col>
              <b-col class="d-flex flex-column align-items-center justify-content-center px-2 py-2  mt-auto" cols="12"
                md="12">

                <FormButton variant="main-green" isBlock isPill class="font-weight-normal text-secondary mt-1 mb-4 w-50"
                  type="submit" @click.native="submit(packages[0], 1, 'Figa Green')" :style="selectedPackage === 1
                    ? 'background-color: #0B9B7D;'
                    : 'background-color: white;'
                    ">
                  <b-icon class="mr-1" icon="check2" size="sm" aria-label="Plus" font-scale="1.4"
                    :variant="selectedPackage === 1 ? 'white' : 'main-green'"></b-icon>
                  <span :style="selectedPackage === 1 ? 'color: white' : 'color: #0B9B7D'
                    ">Select</span>
                </FormButton>
              </b-col>
            </b-card>
          </b-col>


          <b-col class="d-flex flex-column align-items-center justify-content-center  w-100 " no-gutterss>
            <p v-if="!this.$route.query.lockedAccount"  class="pt-2 ml-3 text-primary font-weight-bold font-size" style="text-align: center">
                           Try Free for {{trialPeriodDays}} Days
            </p>
            <b-card no-body
              class="position-relative overflow-hidden border-1 report-card-row w-100 justify-content: space-between; display: flex; flex-direction: column;"
              style="border-color: rgba(11, 155, 125, 0.1);border-width: 5px">
              <div style="min-height: 150px;max-height: 150px">
                <div class="bluecard-title">
                  <h5 class="text-dark font-primary font-weight-bold" >
                    Figa Blue
                  </h5>
                  <div class="image-container">
                  <img src="@/assets/flag.png" alt="Image" class="top-right-image">
                  <span class="image-text">Best Value</span>
                  </div>
                </div>

                <p class="sub-title text-muted text-wrap " style="padding-top: 10px;">Advanced</p>
                <h4 class="text-dark font-primary font-weight-bold price ">
                  {{ price_blue }}
                </h4>
                <p v-if="this.currencyForCountry == 'NZD' || this.currencyForCountry == 'AUD' " class="sub-title text-muted">+GST/Month</p>
                <p v-else class="sub-title text-muted">+VAT/Month</p>
              </div>
              <b-col class="d-flex flex-column align-items-start justify-content-start px-4 py-2" cols="12">
                <div class="d-flex align-items-center" v-for="(i, index) in FigaBluefeatures" :key="index">

                  <b-icon-check-circle-fill class="text-success mr-2"></b-icon-check-circle-fill>
                  <span class="text-muted  featureText" style="font-size: 15px;">{{ i.feature }}</span>

                </div>
              </b-col>
              <b-col class="d-flex flex-column align-items-center justify-content-center px-2 py-2  mt-auto" cols="12"
                md="12">
                <!-- <p class="feature mt-4" href="/">
                  <u>See features</u>
                </p> -->
                <FormButton variant="main-green" isBlock isPill class="font-weight-normal text-secondary mt-1 mb-4 w-50"
                  type="submit" @click.native="submit(packages[1], 2, 'Figa Blue')" :style="selectedPackage === 2
                    ? 'background-color: #0B9B7D;'
                    : 'background-color: white;'
                    ">
                  <b-icon class="mr-1" icon="check2" size="sm" aria-label="Plus" font-scale="1.4"
                    :variant="selectedPackage === 2 ? 'white' : 'main-green'"></b-icon>
                  <span :style="selectedPackage === 2 ? 'color: white' : 'color: #0B9B7D'
                    ">Select</span>
                </FormButton>
              </b-col>
              <!--              </b-container>-->
            </b-card>
          </b-col>


          <b-col class="d-flex flex-column align-items-center justify-content-center  w-100 " no-gutters>
            <p v-if="!this.$route.query.lockedAccount"  class="pt-2 ml-3 text-primary font-weight-bold font-size" style="text-align: center">
                           Try Free for {{trialPeriodDays}} Days
            </p>
            <b-card no-body
              class="position-relative overflow-hidden border-1 report-card-row w-100 justify-content: space-between; display: flex; flex-direction: column;"
              style="border-color: rgba(11, 155, 125, 0.1);border-width: 5px">
               <div style="min-height: 150px;max-height: 150px">
                <div class="Accountcard-title">
                  <h5 class="text-dark font-primary font-weight-bold">
                  Accountant
                </h5>
                <div class="image2-container">
                  <img src="@/assets/flag.png" alt="Image" class="top-left-image">
                  <span class="image2-text">Most Popular</span>
                  </div>
              </div>

              <p class="sub-title text-muted text-wrap " style="padding-top: 10px;">
                for Partners, Accountants & Bookkeepers
              </p>
              <h4 class="text-dark font-primary font-weight-bold price">
                {{ price_accountant }}
              </h4>
              <p v-if="this.currencyForCountry == 'NZD' || this.currencyForCountry == 'AUD' " class="sub-title text-muted">+GST/Month</p>
                <p v-else class="sub-title text-muted">+VAT/Month</p>
              </div>
              <b-col class="d-flex flex-column align-items-start justify-content-start px-4 py-2" cols="12">
                <div class="d-flex align-items-center" v-for="(i, index) in FigaAccountantfeatures" :key="index">

                  <b-icon-check-circle-fill class="text-success mr-2"></b-icon-check-circle-fill>
                  <span class="text-muted  featureText" style="font-size: 15px;">{{ i.feature }}</span>

                </div>
              </b-col>
              <b-col class="d-flex flex-column align-items-end justify-content-end py-2 px-0" no-gutters >

              </b-col>
              <b-col class="d-flex flex-column align-items-center justify-content-center px-2 py-2 mt-auto" cols="12"
                md="12">
                <!-- <a class="feature mt-3" href="/"><u>See features</u></a> -->
                <FormButton variant="main-green" isBlock isPill class="font-weight-normal text-secondary mt-1 mb-4 w-50"
                  type="submit" @click.native="
                    submit(packages[2], 3, 'Figa Accountant/Partners')"
                  :style="selectedPackage === 3 ? 'background-color: #0B9B7D;' : 'background-color: white;'">
                  <b-icon class="mr-1" icon="check2" size="sm" aria-label="Plus" font-scale="1.4"
                    :variant="selectedPackage === 3 ? 'white' : 'main-green'"></b-icon>
                  <span :style="selectedPackage === 3
                    ? 'color: white'
                    : 'color: #0B9B7D'
                    ">Select</span>
                </FormButton>
              </b-col>

            </b-card>
          </b-col>
          <b-col class="d-flex flex-column align-items-center justify-content-center px-0 py-2" cols="12">
            <FormButton variant="main-green" isBlock isPill class="font-weight-normal text-secondary mt-3 mb-4 w-50"
              type="submit" :disabled="!selectedPackage" @click.native="proceed">
              <b-icon variant="white" icon="circle-fill" size="sm" animation="throb" font-scale="1.4"
                v-if="checkout_active"></b-icon>
              <span class="text-white" v-if="!checkout_active">Checkout</span>
            </FormButton>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <template>
  <!-- <b-button @click="$bvModal.show('modal-scoped')">Open Modal</b-button> -->

  <b-modal id="modal-scoped" centered title="Package Purchase">
    <p class="p-0 m-0">
     Dear Customer your free trial period ends within <span class="font-weight-bold">{{ remainDays }} days.</span>  
    </p>
    <p>
      Do you want to purchase this selected Package or Continue with the remaining trial dates? 
    </p>
    <template #modal-footer>
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button size="sm" variant="success" @click="ok">
        Purchase
      </b-button>
      <b-button size="sm" variant="danger" @click="cancel">
        Continue
      </b-button>
      <!-- Button with custom close trigger value -->
    </template>
  </b-modal>
</template>
    <stripe-checkout ref="checkoutRef" mode="payment" :pk="publishable" :line-items="line_items" :success-url="onSuccess"
      @loading="(v) => (loading = v)" />
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import FormButton from "@/components/Form/FormButton";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { trial_period } from "@/util/trialPeriod";
// import { getPackageInfo } from "@/services/stripe.service";
// import { GetPackageTrailDays } from "@/services/settingsbusiness.service";

export default {
  name: "Packages",
  components: {
    StripeCheckout,
    FormButton,
  },
  data() {
    return {
      validateUser:false,
      locked:null,
      continue:false,
      purchase:false,
      trialEnd:false,
      remainDays:0,
      publishable: process.env.VUE_APP_PK,
      loading: false,
      packages: [
        { price: "", quantity: 1, id: "" },
        { price: "", quantity: 1, id: "" },
        { price: "", quantity: 1, id: "" }
      ],
      line_items: [],
      onSuccess: process.env.VUE_APP_STRIPE_CHECKOUT_SUCCESS,
      onCancel: process.env.VUE_APP_STRIPE_CHECKOUT_FAILED,
      selectedPackage: undefined,
      selectedPackageID: undefined,
      selectedPackageName: "",
      checkout_active: false,
      price_green: "$9",
      price_blue: "$29",
      price_accountant: "$15",
      currencyForCountry: "NZD",
      pricing_page: "",
      green: null,
      blue: null,
      accountant: null,
      isAnnual: false,
      isMonthly: true,
      trialPeriodDays :null,
      FigaGreenfeatures: [
        {
          feature: "Add up to 20 Customers or Suppliers"
        },
        {
          feature: "Create up to 2 User Accounts"
        },
        {
          feature: "Quotes and Invoicing in NZD, AUD, USD, FJD"
        },
        {
          feature: "Snapshot Dashboard Metrics"
        },
        {
          feature: "Headline Reports"
        },
        {
          feature: "Accounts Receivables/Payables Summary"
        },
        {
          feature: "Create up to 50 Invoices"
        },
        {
          feature: "Process Payments Using Stripe Connect"
        },
        {
          feature: "Upload Bank Statements"
        },
        {
          feature: "Integrate Easily Using Migration Tool"
        },
        {
          feature: "24 to 48 Hours Virtual Support"
        },


      ],
      FigaBluefeatures: [
        {
          feature: "All the Features in Figa Green"
        },
        {
          feature: "Create up to 3 User Accounts"
        },
        {
          feature: "Create up to 60 Invoices"
        },
        {
          feature: "Manage up to 2 Businesses"
        },
        {
          feature: "GST/VAT Report"
        },
        {
          feature: "Financial Report"
        },

      ],
      FigaAccountantfeatures: [
        {
          feature: "All the Features in Figa Blue"
        },
        {
          feature: "Manual Journal Entry"
        },
        {
          feature: "Fixed Asset Register"
        },
        {
          feature: "Workpaper Folder"
        },
        {
          feature: "IRD Forms"
        },


      ]
    };
  },
  computed: {
    ...mapGetters({
      getTabState: "general/getTabState",
      getCountry: "businessRegistration/getCountry",
      getCurrency: "businessRegistration/getCurrency",
    }),
    tabState: {
      get() {
        return this.getTabState;
      },
      set(val) {
        this.$store.dispatch("general/setTabState", --val);
      },
    },
    currency: {
      get() {
        return this.getCurrency;
      },
    },
    country: {
      get() {
        return this.getCountry;
      },
    },
  },
  watch: {
    country(val) {
      this.setupPrices(val);
    },
    isAnnual() {
      this.fetchPackageInfo();
    }
  },
  async isAnnual() {
    await this.fetchPackageInfo();
    this.setupPrices(this.country);
  },
  async mounted() {
    this.trialPeriodDays = trial_period;
    this.validateUser = this.$route.query.UnlockAccount==='true' ? true:false
    try {
      const{data}=await axios.get(`/packages/days_to_end_trial?UnlockAccount=${this.validateUser}`);
      this.packageActive=data.purchase
      this.trialEnd=data.trialEnd
      this.remainDays=data.daysLeft
      this.locked=data.locked
    } catch (error) {
      console.log('error')
      this.locked=error.response.data.locked
    }
    // const{data} = await GetPackageTrailDays(this.validateUser)
   
    await this.fetchPackageInfo();
    this.setupPrices(this.country);
  },
  methods: {
    ...mapActions({
      registerBusiness: "businessRegistration/registerBusiness",
      saveToLocal: "businessRegistration/saveToLocalStorage",
      workspaceLogin: "auth/workspaceLogin",
      localLogIn: "auth/localLogIn",
    }),
    ...mapGetters({ getBusinessId: "businessRegistration/getBusinessId" }),
    submit(item, pack_number) {
      this.line_items = [];
      this.line_items.push(item);
      this.selectedPackage = pack_number;
      this.selectedPackageName = item.id;
      this.selectedPackageID = item.price;
      console.log(item);
    },
    ok(){
      this.purchase=true
      this.$bvModal.hide('modal-scoped')
      this.checkoutProcess()
    },
    cancel(){
      this.continue=true
      this.$bvModal.hide('modal-scoped')
      this.checkoutProcess()
    },

    async proceed() {
      if(!this.locked){
        this.$bvModal.show('modal-scoped')
        return
      }
      this.checkoutProcess()
    },
    async checkoutProcess(){
      this.checkout_active = true;
      try {
        this.$store.dispatch(
          "businessRegistration/set_package",
          this.selectedPackageName
        );
        await this.saveToLocal();
        // localStorage.setItem("isAnnual", this.isAnnual);
        this.$store.dispatch("storeTempValues/setIsAnnual",this.isAnnual);
        // localStorage.removeItem("selected_package");
        // localStorage.removeItem("selected_package_name");
        // localStorage.removeItem("tracking_id");
        // localStorage.removeItem("switch_package");

        const metaData={
          selected_package:this.selectedPackageID,
          selected_package_name:this.selectedPackageName,
          isAnnual:this.isAnnual,
        }
        const session = await axios.post(`/payments/?UnlockAccount=${this.validateUser}`, {
          package: this.selectedPackageID,
          newUser: true,
          metaData
        });
        // localStorage.setItem("selected_package", this.selectedPackageID);
        // localStorage.setItem("selected_package_name", this.selectedPackageName);
        // localStorage.setItem("tracking_id", session.data.id);
        // localStorage.setItem("switch_package", true);
        // localStorage.setItem("isAnnual", this.isAnnual);
        
        this.$store.dispatch("storeTempValues/setSelectedPackage",this.selectedPackageID);
        this.$store.dispatch("storeTempValues/setSelectedPackageName",this.selectedPackageName);
        this.$store.dispatch("storeTempValues/setTrackingId",session.data.id);
        this.$store.dispatch("storeTempValues/setSwitchPackage",true);
        this.$store.dispatch("storeTempValues/setIsAnnual",this.isAnnual);
        // if(this.purchase){
        //   window.location.replace(session.data.url);
        //   return
        // }
        try {
          // const {data} = await GetPackageTrailDays();
          // console.log(data)
          if (this.locked || this.purchase) {
            window.location.replace(session.data.url);
            return;
          }
        }catch (e) {
          window.location.replace(session.data.url);
          return;
        }
        this.$router.push("/payment/success");
      } catch (error) {
        this.checkout_active = false;
        console.log(error);
      }
    },
    async fetchPackageInfo() {
      
      await axios.get(`packages/stripe_packages/${this.isAnnual}?UnlockAccount=${this.validateUser}`).then((res) => {
        this.green = res.data.green;
        this.blue = res.data.blue;
        this.accountant = res.data.accountant;
        this.setupPrices(this.country);
      }).catch(reason => {
        console.log(reason)
      });
    },
    setupPrices(val) {
      if ("Fiji" === val) {
        this.pricing_page = this.green ? this.green[0].pricing_page : "-";
        this.currencyForCountry = this.green
          ? this.green[0].currencyForCountry
          : "-";

        this.price_green = this.green ? this.green[0].price_green : "-";
        this.packages[0].price = this.green ? this.green[0].priceId : "-";
        this.packages[0].id = this.green ? this.green[0].id : "-";

        this.price_blue = this.blue ? this.blue[0].price_blue : "-";
        this.packages[1].price = this.blue ? this.blue[0].priceId : "-";
        this.packages[1].id = this.blue ? this.blue[0].id : "-";

        this.price_accountant = this.accountant ? this.accountant[0].price_accountant : "-";
        this.packages[2].price = this.accountant ? this.accountant[0].priceId : "-";
        this.packages[2].id = this.accountant ? this.accountant[0].id : "-";

      } else if ("Australia" === val) {
        this.pricing_page = this.green ? this.green[1].pricing_page : "-";
        this.currencyForCountry = this.green
          ? this.green[1].currencyForCountry
          : "-";

        this.price_green = this.green ? this.green[1].price_green : "-";
        this.packages[0].price = this.green ? this.green[1].priceId : "-";
        this.packages[0].id = this.green ? this.green[1].id : "-";

        this.price_blue = this.blue ? this.blue[1].price_blue : "-";
        this.packages[1].price = this.blue ? this.blue[1].priceId : "-";
        this.packages[1].id = this.blue ? this.blue[1].id : "-";

        this.price_accountant = this.accountant ? this.accountant[1].price_accountant : "-";
        this.packages[2].price = this.accountant ? this.accountant[1].priceId : "-";
        this.packages[2].id = this.accountant ? this.accountant[1].id : "-";

      } else {
        this.pricing_page = this.green ? this.green[2].pricing_page : "-";
        this.currencyForCountry = this.green
          ? this.green[2].currencyForCountry
          : "-";

        this.price_green = this.green ? this.green[2].price_green : "-";
        this.packages[0].price = this.green ? this.green[2].priceId : "-";
        this.packages[0].id = this.green ? this.green[2].id : "-";

        this.price_blue = this.blue ? this.blue[2].price_blue : "-";
        this.packages[1].price = this.blue ? this.blue[2].priceId : "-";
        this.packages[1].id = this.blue ? this.blue[2].id : "-";

        this.price_accountant = this.accountant ? this.accountant[2].price_accountant : "-";
        this.packages[2].price = this.accountant ? this.accountant[2].priceId : "-";
        this.packages[2].id = this.accountant ? this.accountant[2].id : "-";
      }
    },
    toggleMonthly() {
      this.isMonthly = true;
      this.isAnnual = false;
    },
    toggleAnnually() {
      this.isMonthly = false;
      this.isAnnual = true;
    }
  },
};
</script>

<style scoped>
.container {
  width: 100%;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input,
label {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 0;
}

.toggle-btn {
  display: flex;
  align-items: center;

}

.switch {
  position: relative;
  display: inline-block;
  width: 400px;
  /* Adjust the width as needed */
  height: 45px;

}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  transition: 0.4s;
  border-radius: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border: 2px solid #0b9b7d;
  /* Add border style */
  box-sizing: border-box;

}

.container .btn {
  width: 200px;
  height: 35px;
  padding: 4px 8px;
  border: none;
  background-color: white;
  cursor: pointer;
  border-radius: 34px;
  color: #0b9b7d;
  transition: background-color 0.3s ease;
  font-size: 16px;
}

.container .btn.active {
  background-color: #0b9b7d;
  color: white;
}

input:checked+.slider {
  background-color: white;
}




input:checked+.slider .active {
  background-color: #0b9b7d;
}



.font-size {
  font-size: 0.87vw;
}

@media (max-width: 1275px) {
  .font-size {
    font-size: 0.88vw;
  }
}

@media (max-width: 1199px) {
  .font-size {
    font-size: 1.5vw;
  }
}

@media (max-width: 767px) {
  .font-size {
    font-size: 3.5vw;
  }
}

/* @media (max-width: 1868px) {
  .font-size {
    font-size: 16.5px;
  }
}

@media (max-width: 1828px) {
  .font-size {
    font-size: 16px;
  }
}

@media (max-width: 1767px) {
  .font-size {
    font-size: 15.7px;
  }
}

@media (max-width: 1736px) {
  .font-size {
    font-size: 15.5px;
  }
} */

/* @media (max-width: 1726px) {
  .font-size {
    font-size: 15px;
  }
} */
.noClick {
  pointer-events: none;
}

.star::after {
  content: "*";
}

.text__center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  filter: blur(5px);
}

.space {
  margin-top: 12px;
}

.small-margin {
  margin-top: 20px;
}

.feature {
  text-align: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #828282;
  line-height: 0px;
  margin-bottom: 10px;
}

.price {
  text-align: center;
  font-size: 30px;
}

.card-title {
  height: 15%;
  background-color: #ffe400;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 2%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

}
.bluecard-title {
  height: 15%;
  background-color: #ffe400;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 2%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
 border: 3px solid #0b9b7d;
  /* Add border style */
  box-sizing: border-box;

}
.Accountcard-title {
  height: 15%;
  background-color: #ffe400;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 2%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
 border: 3px solid #0b9b7d;
  /* Add border style */
  box-sizing: border-box;

}
.sub-title {
  text-align: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* color: #828282; */
  line-height: 1px;

}

.featureText {

  font-family: "Lato";
  font-style: normal;

  font-size: 15px;
  /* color: #828282; */
  line-height: 30px;
}

.font-small {
  font-size: 11px;
}

.report-card-row {
  margin: 10px 10px 10px 10px;
  min-height: 580px;
  max-height: 580px;
  max-width: 380px;
  min-width: 350px;
  border-radius: 5px;
  overflow-wrap: break-word;
  hyphens: manual;
  /*border: 1px solid var(--main-green);*/
  border-color: white;
  box-shadow: rgba(11, 155, 125, 0.1) 0px 1px 1px,
    rgba(11, 155, 125, 0.1) 0px 2px 2px, rgba(11, 155, 125, 0.1) 0px 4px 4px,
    rgba(11, 155, 125, 0.1) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

#login-form {
  height: 90vh;
  padding-top: 40px;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.title {
  padding-top: 1vh;
  font-size: 20px;
  padding-left: 14%;
}

.login-container-card {
  width: 100%;
  min-width: 300px;
  min-height: 300px;
  max-width: 600px;
}

.login-container-card .register-button {
  opacity: 1;
  transition: opacity 200ms ease-in, box-shadow 200ms ease-in,
    color 200ms ease-in;
}

.login-container-card .register-button:hover {
  opacity: 0.7;
  box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.15) !important;
}

.btn-disabled {
  background: rgba(11, 155, 125, 0.2);
  /* light green */
  color: #fff;
  border: rgba(11, 155, 125, 0.2);
  cursor: not-allowed;
}
.bluecard-title .top-right-image {
  position: absolute;
  top: 0;
  right: 0;
 padding-right: 20px;
  width: 110px; /* Adjust the width as needed */
  height: 45px; /* Preserve aspect ratio */
}
.Accountcard-title .top-left-image {
  position: absolute;
  top: 0;
  right: 0;
 padding-right: 20px;
  width: 110px; /* Adjust the width as needed */
  height: 45px;

}
.Accountcard-title.image2-container {
  position: relative;
  display: inline-block;

}
.Accountcard-title .image2-text {
  position: absolute;
  top: 0;
  right: 0;
 font-size: 14px;
margin-right: 18px;
  color: yellow; /* Adjust text color as needed */
  padding: 5px;
}
.bluecard-title .image-text {
  position: absolute;
  top: 0;
  right: 0;
 font-size: 14px;
margin-right: 25px;
  color: yellow; /* Adjust text color as needed */
  padding: 5px;
}
.bluecard-title.image-container {
  position: relative;
  display: inline-block;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .login-container-card {
    min-height: fit-content;
    margin-right: 0;
  }
}

@media (max-width: 1200px) {
  .s-margin {
    margin-right: 10px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .login-container-card {
    width: 100%;
    margin-right: 0;
  }
}

@media screen and (max-width: 1851px) {
  .text-responsive {
    font-size: 10px;
    color: red;
  }
}

/* @media (max-width: 1560px) {
  .hidden {
    display: static;
  }
} */
</style>
