<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- table container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
        no-gutters
      >
        <b-card no-body class="shadow-sm border-0 table-container-card w-100">
          <b-row
            class="d-flex flex-row align-items-center justify-content-between w-75 my-1 my-md-2 pl-4 pt-4"
            no-gutters
          >
            <!-- full row input (Customer) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 pt-4 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Item Code</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="user-name-group"
                  id="user-name-group-input"
                  class="text-prime-gray rounded-2"
                  :state="productNameError.status"
                  :invalidFeedback="`${productNameError.message}`"
                  label="Item Codes"
                  v-model="productName"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
                <!-- <span>{{ `Number of Charters Remaining${productName.length}` }}</span> -->
              </b-col>
            </b-row>
            <!-- full row input (Full name) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Description</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="user-name-group"
                  id="user-name-group-input"
                  class="text-prime-gray rounded-2"
                  label="Item Codes Description"
                  v-model="productDescription"
                  isRequired
                  :state="productDescriptionError.status"
                  :invalidFeedback="`${productDescriptionError.message}`"
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                  isTextArea
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (Phone) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Unit Price</span
                >
              </b-col>
              <b-col cols="8">
                <b-input-group :prepend="currency">
                  <b-form-input
                    groupId="user-name-group"
                    id="user-name-group-input"
                    class="text-prime-gray rounded-2"
                    label="Price"
                    placeholder="0.00"
                    v-model="productPrice"
                    :state="productPriceError.status"
                    :invalidFeedback="`${productPriceError.message}`"
                    isRequired
                    :whiteBG="false"
                    trim
                    form="login-form"
                    type="number"
                  ></b-form-input>
                </b-input-group>
                <b-row
                  class="d-flex flex-row align-items-start justify-content-end w-100 py-1"
                  no-gutters
                >
                  <b-col cols="8">
                    <b-input-group prepend="GST/VAT Rate">
                      <b-form-input
                        groupId="user-name-group"
                        id="user-name-group-input"
                        class="text-prime-gray rounded-2"
                        label="Price"
                        placeholder="0.00"
                        v-model="gstVat"
                        :state="gstVatError.status"
                        :invalidFeedback="`${gstVatError.message}`"
                        isRequired
                        :whiteBG="false"
                        trim
                        form="login-form"
                        type="number"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-row
                  class="d-flex flex-row align-items-start justify-content-end w-100 py-1"
                  no-gutters
                >
                  <b-col cols="8">
                    <b-input-group prepend="Total Price" :isDisabled="true">
                      <b-form-input
                        groupId="user-name-group"
                        id="user-name-group-input"
                        class="text-prime-gray rounded-2"
                        label="Price"
                        placeholder="0.00"
                        v-model="totalPrice"
                        isRequired
                        trim
                        form="login-form"
                        type="text"
                        disabled="true"
                        style="background-color: white; font-weight: 900"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <!-- full row input (GL code) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 pt-4 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >GL code</span
                >
                <!-- :state="glcodeError.status"
                  :invalidFeedback="`${glcodeError.message}`" -->
                <!-- groupId="user-name-group"
                  id="user-name-group-input"
                  class="text-prime-gray rounded-2"

                  label="gl Codes"
                  v-model="glcode"
                  isRequired
                  :whiteBG="false"
                  form="login-form"
                  type="text"
                  :options="glcodeList" -->
              </b-col>
              <b-col cols="8">
                <FormSelect
                  groupId="user-name-group"
                  id="user-name-group-input"
                  class="text-prime-gray rounded-2"
                  label="GL Codes"
                  v-model="productGLCode"
                  isRequired
                  :whiteBG="false"
                  :options="glcodeList"
                  form="login-form"
                  type="text"
                ></FormSelect>
              </b-col>
            </b-row>
          </b-row>

          <!-- form action buttons  -->
          <b-row
            class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end my-4 w-75"
          >
            <b-button
              variant="gray-550"
              pill
              class="my-2 my-sm-0 mr-0 mr-sm-2 border-0 bg-ghost-white action-btn"
              @click="cancel"
            >
              Cancel
            </b-button>
            <b-button
              variant="main-green"
              pill
              class="my-2 my-sm-0 ml-0 ml-sm-2 border-0 action-btn-save"
              @click="addProduct"
            >
              {{ this.$route.meta.isEdit ? "Update" : "Save" }}
            </b-button>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>
<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";

// services
import { GetGlCode } from "@/services/items.service";

import {
  AddItems,
  GetSingleItem,
  SearchItem,
  UpdateItem,
} from "@/services/items.service";
import { GetCurrentWorkspace } from "@/services/settingsbusiness.service";

export default {
  name: "CustomersCreate",
  components: {
    HomeLayout,
    FormInput,
    FormSelect,
  },
  data() {
    return {
      productName: null,
      productDescription: null,
      productPrice: null,
      gstVat: null,
      productGLCode: null,
      isEditProduct: false,
      glcode: null,
      glcodeList: [],
      totalPrice: null,
      productNameError: {
        status: null,
        message: "Please fill out this field",
      },
      productDescriptionError: {
        status: null,
        message: "Please fill out this field",
      },
      productPriceError: {
        status: null,
        message: "Please fill out this field",
      },
      gstVatError: {
        status: null,
        message: "Please fill out this field",
      },
      currency: localStorage.getItem("CURRENCY"),
    };
  },
  watch: {
    productName(value) {
      if (value) {
        this.productNameError.status = true;
        if (value.length >= 10) {
          this.productNameError.status = false;
          this.productNameError.message =
            "Item name should be less than 10 characters";
        }
      } else {
        this.productNameError.status = false;
        this.productNameError.message = "Please fill out this field";
      }
    },
    productDescription(value) {
      if (value) {
        this.productDescriptionError.status = true;
        if (value.length >= 50) {
          this.productDescriptionError.status = false;
          this.productDescriptionError.message =
            "Item description should be less than 50 characters";
        }
      } else {
        this.productDescriptionError.status = false;
        this.productDescriptionError.message = "Please fill out this field";
      }
    },
    productPrice(value) {
      if (value) {
        this.productPriceError.status = true;
        this.calculateTotal(value, this.gstVat);
      } else {
        this.productPriceError.status = false;
        this.productPriceError.message = "Please fill out this field";
      }
    },
    gstVat(value) {
      if (value) {
        this.gstVatError.status = true;
        this.calculateTotal(this.productPrice, value);
      } else {
        this.gstVatError.status = false;
        this.gstVatError.message = "Please fill out this field";
      }
    },
  },
  async mounted() {
    await this.loadCurrentWorkspace();
    // await this.initFn();
    this.isEditProduct = this.$route.meta.isEdit;
    if (this.isEditProduct) {
      this.initEditProductFn(this.$route.params.productId);
      // console.log(this.$route.params.invoiceId);
    }

    try {
      let { data } = await GetGlCode();
      //Fixed GLcode Undefined
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].startsWith("undefined")) {
          continue;
        }
        arr.push(data[i]);
      }
      // this.glOptions = arr
      this.glcodeList = arr;
    } catch (error) {
      console.log("GL Error" + error);
    }
  },
  methods: {
    //remove commas from the numbers
    // removeCommas(val) {
    //   const number = parseFloat(val.replaceAll(",", ""));
    //   return number;
    // },
    // errorFn() {
    //   let errorList = [];
    //   if (!this.productNameError.status) {
    //     errorList.push(this.productNameError.message);
    //   }
    //   if (!this.productDescriptionError.status) {
    //     errorList.push(this.productPriceError.message);
    //   }
    //   return errorList;
    // },
    calculateTotal(priceInput, gstInput) {
      const price = Number(priceInput);
      const gst = Number(gstInput);
      this.totalPrice = Number(price) + (price * gst) / 100;
    },
    async loadCurrentWorkspace() {
      let { data } = await GetCurrentWorkspace();
      this.gstVat = data.vat_rate || 15;
      this.currency = data.currency;
    },
    async addProduct() {
      if (
        this.productNameError.status &&
        this.productDescriptionError.status &&
        this.productPriceError.status
      ) {
        if (this.isEditProduct) {
          let payload = {
            id: this.$route.params.productId,
            name: this.productName,
            glcode: this.productGLCode,
            price: parseInt(this.productPrice),
            description: this.productDescription,
          };

          try {
            let { data } = await UpdateItem(payload);
            if (data.msg == "Updated succesfully") {
              console.log("updated data", data);
              let payloadNotify = {
                isToast: true,
                title: "Item Codes Update",
                content: "The Item Codes has been updated successfully",
                variant: "success",
              };
              this.$store.dispatch("notification/setNotify", payloadNotify);
              console.log("notify", payloadNotify);
              this.$router.push("/products");
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          if (!(await this.checkDuplicate())) {
            let payload = {
              name: this.productName,
              price: this.productPrice,
              description: this.productDescription,
              glcode: this.productGLCode,
              tax_per_item: this.gstVat,
            };
            console.log(payload);
            try {
              let { data, status } = await AddItems(payload);
              if (status == 200) {
                console.log("updated data", data);
                let payloadNotify = {
                  isToast: true,
                  title: "Item Codes Create",
                  content: "The Item Codes has been created successfully",
                  variant: "success",
                };
                this.$store.dispatch("notification/setNotify", payloadNotify);
                console.log("notify", payloadNotify);
                this.$router.push("/products");
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      } else {
        const errorList = this.validateForm();
        let payloadNotify = {
          isToast: true,
          title: "Item Code",
          content: "Error: Unable to create the item code",
          list: errorList,
          variant: "danger",
        };

        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    validateForm() {
      let errorList = [];
      if (!this.productName || !this.productNameError.status) {
        this.productNameError.status = false;
        // this.productNameError.message="Please add item code"
        errorList.push(this.productNameError.message);
      }
      if (!this.productDescription || !this.productDescriptionError.status) {
        this.productDescriptionError.status = false;
        // this.productDescriptionError.message="Please add item description"
        errorList.push(this.productPriceError.message);
      }
      if (!this.productPrice || !this.productPriceError.status) {
        this.productPriceError.status = false;
        // this.productPriceError.message="Please add item price"
        errorList.push(this.productPriceError.message);
      }
      // if (!this.productPrice ||!this.productName) {
      //   this.productNameError.status = false;
      //   this.productNameError.message = "Please fill out this field";
      //   errorList.push(this.productNameError.message);

      // }
      // if (!this.productDescription) {
      //   this.productDescriptionError.status = false;
      //   this.productDescriptionError.message = "Please fill out this field";
      //   errorList.push(this.productDescriptionError.message);

      //   }
      //   if (!this.productPrice) {
      //   this.productPriceError.status = false;
      //   this.productPriceError.message = "Please fill out this field";
      //   errorList.push(this.productPriceError.message);

      //   }
      return errorList;
      //   this.$store.dispatch("notification/setNotify", payloadNotify);
      // }
      // if (!this.productPrice) {
      //   this.productPriceError.status = false;
      //   this.productPriceError.message = "Please fill out this field";
      //   let payloadNotify = {
      //     isToast: true,
      //     title: "Error: Unable to save the Item Codes",
      //     content: "Please enter the the Item Codes name",
      //     variant: "danger",
      //   };
      //   this.$store.dispatch("notification/setNotify", payloadNotify);
      // }
    },
    async initEditProductFn(val) {
      try {
        let { data } = await GetSingleItem(val);
        console.log("get single", data);
        (this.productName = data.name),
          (this.productPrice = data.price),
          (this.productDescription = data.description);
        this.productGLCode = data.glcode;
        this.gstVat = data.tax_per_item;
      } catch (error) {
        console.log(error);
      }
    },
    cancel() {
      this.$router.push("/products");
    },
    async checkDuplicate() {
      const { data } = await SearchItem({
        text: this.productName,
        full_name: true,
      });
      if (data.data.length > 0) {
        this.productNameError.status = false;
        this.productNameError.message = "Duplicate item code!";
      }
      return data.data.length > 0;
    },
  },
};
</script>
<style scoped>
/* table card */
.table-card-row {
  height: 100%;
}
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 520px;
  border-radius: 5px;
  border: 1px solid var(--light);
}
/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}
.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}
.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}
.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}
.table-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}
/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
.table-container-row .table-element {
  min-width: 1000px;
}
/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}
.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}
.table-element >>> .table-body td {
  vertical-align: middle;
}
.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}
.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}
.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}
/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}
/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}
/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: var(--secondary);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}
/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}
.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}
/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .edit-btn > .edit-icon {
  color: #0b9b7d;
  transition: color 200ms ease-in;
}
/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  background-color: #0b9b7d;
}
.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: var(--white);
}
/* delete icon button (default state) */
.table-element >>> .table-body .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}
/* delete icon button (hover state) */
.table-element >>> .table-body .delete-btn:hover {
  background-color: var(--danger);
}
.table-element >>> .table-body .delete-btn:hover > .delete-icon {
  color: var(--white);
}
/* expand icon button (default state) */
.table-element >>> .table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .expand-btn > .expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}
/* expand icon button (hover state) */
.table-element >>> .table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}
.table-element >>> .table-body .expand-btn:hover > .expand-icon {
  color: var(--white);
}
.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}
.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}
/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}
/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}
.table-pagination >>> .custom-page-item button:hover {
  color: var(--secondary);
  border-color: var(--secondary);
  background-color: var(--light);
}
.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}
.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}
.action-btn {
  height: 50px;
  min-width: 120px;
}

.action-btn-save {
  height: 50px;
  min-width: 120px;
}

.action-btn-save:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }
  .action-btn {
    width: 80%;
  }
}
</style>
