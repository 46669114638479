<template>
  <b-row
    class="d-flex flex-row align-items-center justify-content-start w-100"
    no-gutters
  >
    <!-- Contact Title row  -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-start"
      no-gutters
    >
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <h5
          class="text-left text-dark font-weight-bold font-primary"
          style="fontsize: 1.1rem"
        >
          Invite a user : Personal Account
        </h5>
      </b-col>
    </b-row>
    <!-- full row -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100"
      no-gutters
    >
      <!-- left column -->
      <b-col
        class="d-flex flex-column align-items-start justify-content-start"
        cols="9"
      >
        <!-- full row input (Customer) -->
        <b-row
          class="d-flex flex-row align-items-start justify-content-end w-100 mt-3"
          no-gutters
        >
          <b-col cols="3">
            <span class="text-left text-dark font-primary font-weight-normal"
              >Name *</span
            >
          </b-col>
          <b-col cols="9">
            <FormInput
              groupId="business-name-group"
              id="business-name-group-input"
              class="text-prime-gray rounded-2"
              :state="userNameError.status"
              :invalidFeedback="`${userNameError.message}`"
              label="Add user name"
              v-model="userName"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
            ></FormInput>
          </b-col>
        </b-row>
        <!-- full row input (Phone) -->
        <b-row
          cols="12"
          class="d-flex flex-row align-items-start justify-content-end w-100"
          no-gutters
        >
          <b-col cols="3">
            <span class="text-left text-dark font-primary font-weight-normal"
              >Email *</span
            >
          </b-col>
          <b-col cols="9">
            <FormInput
              groupId="business-name-group"
              id="business-email-group-input"
              class="text-prime-gray rounded-2"
              :state="userEmailError.status"
              :invalidFeedback="`${userEmailError.message}`"
              label="Add user email"
              v-model="userEmail"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
            ></FormInput>
          </b-col>
        </b-row>
        <!-- full row input (Phone) -->
        <!-- <b-row
          class="d-flex flex-row align-items-start justify-content-end w-100"
          no-gutters
        >
          <b-col cols="9">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio
                v-model="role"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="User"
                class="mb-3"
                >Read-Only
              </b-form-radio>
              <b-form-radio
                v-model="role"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="Standard_User"
                class="mb-3"
                >Standard Access
              </b-form-radio>
              <b-form-radio
                v-model="role"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="Advisor"
                class="mb-3"
                >Advisor
              </b-form-radio>
              <b-form-radio
                v-model="role"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="Bookkeeper"
                class="mb-3"
                >Bookkeeper
              </b-form-radio>
              <b-form-radio
                v-model="role"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="Bookkeeper"
                class="mb-3"
                >Accountant
              </b-form-radio>
            </b-form-group>
          </b-col>
        </b-row> -->
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100"
          no-gutters
        >
          <!-- <b-col cols="8">
            <p
              v-if="userRoleError.status == true"
              class="text-danger text-lg text-center"
            >
              {{
                this.userRoleError.message &&
                "Please select a role for the invite user"
              }}
            </p>
          </b-col> -->
        </b-row>
        <!-- form action buttons  -->
        <b-row class="d-flex justify-content-between mt-3 w-100 mx-0">
          <b-link
            @click="gobackFn()"
            class="font-primary text-gray-750 font-weight-normal"
            >Go back
          </b-link>
          <FormButton
            variant="main-green"
            isPill
            class="border-0 px-4 add-question-btn"
            :isLoading="isLoading"
            @click.native="sendInvitation"
            :disabled="getUserRole.role.includes('User')"
            >Send Invite
          </FormButton>
        </b-row>
        <b-row
          v-if="invitationError.status === true"
          class="d-flex flex-row align-items-center justify-content-center w-100 mb-3"
          no-gutters
        >
          <b-col cols="12" md="10">
            <span class="text-danger text-left">{{
              this.invitationError.message
            }}</span>
          </b-col>
        </b-row>
      </b-col>
      <!-- <b-col
        class="d-flex flex-column align-items-start justify-content-start pl-5 pt-5"
        cols="5"
      >
        <h4 class="permission-text mb-5">Accountant Permissions</h4>
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 mb-3"
          cols="12"
        >
          <b-col
            cols="7"
            class="d-flex flex-column align-items-start justify-content-start access-text"
            >Sales
          </b-col>
          <b-col
            cols="5"
            class="d-flex flex-column align-items-start justify-content-start access-text"
            >{{ access.sales }}
          </b-col>
        </b-row>
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 mb-3"
          cols="12"
        >
          <b-col
            cols="7"
            class="d-flex flex-column align-items-start justify-content-start access-text"
            >Transactions
          </b-col>
          <b-col
            cols="5"
            class="d-flex flex-column align-items-start justify-content-start access-text"
            >{{ access.transactions }}
          </b-col>
        </b-row>
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 mb-3"
          cols="12"
        >
          <b-col
            cols="7"
            class="d-flex flex-column align-items-start justify-content-start access-text"
            >Chart of Accounts
          </b-col>
          <b-col
            cols="5"
            class="d-flex flex-column align-items-start justify-content-start access-text"
            >{{ access.coa }}
          </b-col>
        </b-row>
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 mb-3"
          cols="12"
        >
          <b-col
            cols="7"
            class="d-flex flex-column align-items-start justify-content-startaccess-text access-text"
            >Reports
          </b-col>
          <b-col
            cols="5"
            class="d-flex flex-column align-items-start justify-content-start access-text"
            >{{ access.reports }}
          </b-col>
        </b-row>
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 mb-3"
          cols="12"
        >
          <b-col
            cols="7"
            class="d-flex flex-column align-items-start justify-content-start access-text"
            >User Management
          </b-col>
          <b-col
            cols="5"
            class="d-flex flex-column align-items-start justify-content-start access-text"
            >{{ access.user_management }}
          </b-col>
        </b-row>
      </b-col> -->
    </b-row>
  </b-row>
</template>

<script>
// @ is an alias to /src
// components
import FormInput from "@/components/Form/FormInput";
import FormButton from "@/components/Form/FormButton";

// services
import { sendInvitaion } from "@/services/invitations.service";

// util
import CheckEmail from "@/util/CheckEmail";
import { mapGetters } from "vuex";

export default {
  name: "InviteUser",
  components: {
    FormInput,
    FormButton,
  },
  props: {
    itemContent: {
      type: Object,
    },
  },
  data() {
    return {
      role: null,
      userName: null,
      userEmail: null,
      userNameError: {
        status: null,
        message: "",
      },
      userEmailError: {
        status: null,
        message: "",
      },
      userRoleError: {
        status: null,
        message: "",
      },
      invitationError: {
        status: null,
        message: null,
      },
      isLoading: false,
      access: {
        sales: "No Access",
        transactions: "No Access",
        coa: "No Access",
        reports: "No Access",
        user_management: "No Access",
      },
    };
  },
  watch: {
    userName(value) {
      if (value) {
        this.userNameError.status = true;
      } else {
        this.userNameError.status = false;
        this.userNameError.message = "Please fill out this field";
      }
    },
    userEmail(value) {
      this.userEmailError.status = true;
      if (!value) {
        this.userEmailError.status = false;
        this.userEmailError.message = "Please fill out this field";
      } else if (CheckEmail(value) == false) {
        this.userEmailError.status = false;
        this.userEmailError.message = "Email is not valid";
      }
    },
    // role(value) {
    //   this.userRoleError.status = null;
    //   if (!value) {
    //     this.userRoleError.status = true;
    //     this.userRoleError.message = "Please fill out this field";
    //   } else if (
    //     ![
    //       "Accountant",
    //       "Bookkeeper",
    //       "Advisor",
    //       "Standard_User",
    //       "User",
    //     ].includes(value)
    //   ) {
    //     this.userRoleError.status = true;
    //     this.userRoleError.message = "Selected role is Invalid";
    //   } else {
    //     if (
    //       value === "Accountant" ||
    //       value === "Bookkeeper" ||
    //       value === "Advisor"
    //     ) {
    //       this.access.sales = "Full Access";
    //       this.access.transactions = "Full Access";
    //       this.access.coa = "Full Access";
    //       this.access.reports = "Full Access";
    //       this.access.user_management = "Full Access";
    //     } else if (value === "Standard_User") {
    //       this.access.sales = "Full Access";
    //       this.access.transactions = "Read Only";
    //       this.access.coa = "Read Only";
    //       this.access.reports = "Read Only";
    //       this.access.user_management = "Read Only";
    //     } else if (value === "User") {
    //       this.access.sales = "Read Only";
    //       this.access.transactions = "Read Only";
    //       this.access.coa = "Read Only";
    //       this.access.reports = "Read Only";
    //       this.access.user_management = "Read Only";
    //     }
    //   }
    // },
  },
  async mounted() {
    await this.initFn();
    this.isEditProduct = this.$route.meta.isEdit;
    if (this.isEditProduct) {
      this.initEditProductFn(this.$route.params.productId);
      // console.log(this.$route.params.invoiceId);
    }
  },
  methods: {
    async initFn() {
      console.log("item content user", this.itemContent);
      // this.fullName = this.itemContent.name;
    },
    gobackFn() {
      this.$emit("goBackFn");
    },
    async sendInvitation() {
      if (this.validateForm()?.length != 0) return;
      console.log("Sendiing Invitations");
      try {
        this.isLoading = true;
        const res = await sendInvitaion({
          name: this.userName,
          email: this.userEmail,
          role: this.role,
        });
        // TODO show notifications
        if (res.status === 200) {
          let active_package = this.getActivePackage;
          active_package.remaining_users = active_package.remaining_users - 1;
          this.$store.dispatch("auth/setUserPackage", active_package);
          this.invitationError.status = false;
          this.invitationError.message = "";
          let payloadNotify = {
            isToast: true,
            title: "Succeeded!",
            content: res.data.msg,
            variant: "success",
          };
          this.$emit("goBackFn");
          await this.$store.dispatch("notification/setNotify", payloadNotify);
          setTimeout(() => this.resetFn(), 3200);
        } else {
          this.invitationError.status = false;
          this.invitationError.message = "Invitation was not sent";
        }
      } catch (error) {
        this.invitationError.status = false;
        this.invitationError.message =
          "Invitation was not sent" + error.message;
        let payloadNotify = {
          isToast: true,
          title: "Error!",
          content: error.response.data.msg,
          variant: "danger",
        };
        await this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.isLoading = false;
      }
    },
    validateForm() {
      var errorList = [];
      if (!this.userName) {
        this.userNameError.status = false;
        this.userNameError.message = "Please fill out this field";
        errorList.push("Please fill out this field");
      }
      if (!this.userEmail) {
        this.userEmailError.status = false;
        this.userEmailError.message = "Please fill out this field";
        errorList.push("Please fill out this field");
      } else if (CheckEmail(this.userEmail) == false) {
        this.userEmailError.status = false;
        this.userEmailError.message = "Email is not valid";
        errorList.push("Email is not valid");
      }
      // TODO validate role
      // if (!this.role) {
      //   this.userRoleError.status = true;
      //   this.userRoleError.message = "Please fill out this field";
      //   errorList.push("Please fill out this field");
      // } else if (
      //   ![
      //     "Accountant",
      //     "Bookkeeper",
      //     "Advisor",
      //     "Standard_User",
      //     "User",
      //   ].includes(this.role)
      // ) {
      //   this.userRoleError.status = true;
      //   this.userRoleError.message = "Selected role is Invalid";
      //   errorList.push("Selected role is Invalid");
      // }

      return errorList;
    },
    resetFn() {
      this.userName = null;
      this.userEmail = null;
      this.role = null;
      setTimeout(() => {
        this.userNameError.status = null;
        this.userEmailError.status = null;
        this.userRoleError.status = null;
      }, 200);
    },
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole",
      getActivePackage: "auth/getActivePackage",
    }),
  },
};
</script>

<style scoped>
/* table card */
.table-card-row {
  height: 100%;
}

.add-question-btn:hover {
  background: var(--main-green);
  box-shadow: inset 0px 0px 10px #ffffff;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 520px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}

.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}

.table-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 1000px;
}

/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

.table-element >>> .table-body td {
  vertical-align: middle;
}

.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}

.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}

/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}

/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: var(--main-green);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}

/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .edit-icon {
  color: var(--main-green);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  background-color: var(--main-green);
}

.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: var(--white);
}

/* delete icon button (default state) */
.table-element >>> .table-body .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}

/* delete icon button (hover state) */
.table-element >>> .table-body .delete-btn:hover {
  background-color: var(--danger);
}

.table-element >>> .table-body .delete-btn:hover > .delete-icon {
  color: var(--white);
}

/* expand icon button (default state) */
.table-element >>> .table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .expand-btn > .expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}

/* expand icon button (hover state) */
.table-element >>> .table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .expand-btn:hover > .expand-icon {
  color: var(--white);
}

.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}

.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}

/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}

.table-pagination >>> .custom-page-item button:hover {
  color: var(--main-green);
  border-color: var(--main-green);
  background-color: var(--light);
}

.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}

.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}

.action-btn {
  height: 50px;
  min-width: 120px;
}

.permission-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: var(--gray-750);
}

.access-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #222831;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }

  .action-btn {
    width: 80%;
  }
}
</style>
